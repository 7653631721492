import React from "react";
import "./style.scss";

export default ({ onClick }) => {
  return (
    <h4
      onClick={onClick}
      type="submit"
      className="sc-button sc-notify-me-button"
    >
      <span>Notify Me</span>
    </h4>
  );
};
