import React from "react";
import "./style.scss";

export default () => {
  return (
    <svg
      id="Capa_1"
      className="sc-logo"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1943.27 556.81"
    >
      <rect
        class="cls-1"
        x="166.63"
        y="148.1"
        width="218.77"
        height="35.19"
        rx="17.59"
      />
      <rect
        class="cls-1"
        x="184.9"
        y="223.24"
        width="218.77"
        height="35.19"
        rx="17.59"
      />
      <rect
        class="cls-1"
        x="151.49"
        y="298.38"
        width="215.1"
        height="35.19"
        rx="17.59"
      />
      <rect
        class="cls-1"
        x="171.73"
        y="373.53"
        width="218.77"
        height="35.19"
        rx="17.59"
      />
      <text class="cls-2" transform="translate(493.32 367.33)">
        stackcoin
      </text>
    </svg>
  );
};
