import React, { useState } from 'react';
import SubscribeForm from './components/Mailchimp';
import Particle from './components/Particle';
import Button from './components/Button';
import Logo from './components/Logo';

import './styles.css';

export default function App() {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleClick = () => {
    setIsFormVisible(true);
  };

  return (
    <main className="sc-page-wrapper">
      <Particle />
      <section className="sc-content">
        <header className="sc-header">
          <Logo />
        </header>
        <p className="sc-message-wrapper">
          <span className="sc-message">Seed round funding coming soon</span>
        </p>
        <h3 className="sc-coming-soon">
          Sign up for our newsletter to get updates on the development.
        </h3>
        {isFormVisible ? <SubscribeForm /> : <Button onClick={handleClick} />}
      </section>
      <footer className="sc-footer">
        {/* <Social /> */}
        <p>Stackcoin LLC.</p>
      </footer>
    </main>
  );
}
