import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "./style.scss";

const url =
  "https://stackcoin.us7.list-manage.com/subscribe/post?u=d52723af9e20d0493806785fd&amp;id=0e401d5cc2";

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div className="sc-form-wrapper">
      {status === "sending" && (
        <div className="sc-form-wrapper__message">sending...</div>
      )}
      {status === "error" && (
        <div
          className="sc-form-wrapper__message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="sc-form-wrapper__message"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="sc-form">
        <input
          ref={(node) => (email = node)}
          type="email"
          placeholder="Your email"
        />
        <button onClick={submit}>Submit</button>
      </div>
    </div>
  );
};

export default () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={(formData) => subscribe(formData)}
      />
    )}
  />
);
